<template>
  <div :class="props.class">
    <Label
      v-if="label"
      class="font-custom-mono-space text-sm font-[600] text-subtitle"
      :for="id ?? name ?? fieldId"
    >
      {{ label }}
      <span v-if="requiredSign && required">{{ requiredSign }}</span>
    </Label>
    <div class="relative mt-1">
      <input
        :id="id ?? name ?? fieldId"
        v-bind="$attrs"
        v-model="value"
        :class="
          twMerge(
            'w-full rounded border border-stroke bg-white px-3 py-2 text-sm text-subtitle',
            isSecret && 'pr-12',
            props.inputClass
          )
        "
        :type="isSecret ? (isSecretVisible ? 'text' : 'password') : type"
        :aria-invalid="!!errorMessage"
      />
      <button
        v-if="props.isSecret"
        type="button"
        aria-label="Toggle password visibility"
        :aria-pressed="isSecretVisible"
        class="absolute right-3 top-1/2 -translate-y-1/2 p-1"
        @click="isSecretVisible = !isSecretVisible"
      >
        <Icon
          :icon="isSecretVisible ? 'akar-icons:eye-slashed' : 'akar-icons:eye'"
          class="size-4 text-icon-fill-dark"
        />
      </button>
    </div>
    <slot name="suffix" />
    <span v-if="errorMessage" class="mt-0.5 text-xs text-red-500">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script setup lang="ts" generic="T = string">
import { Icon } from "@iconify/vue";
import { Label } from "radix-vue";
import { twMerge } from "tailwind-merge";
import { useField } from "vee-validate";

export type InputProps = {
  label?: string;
  id?: string;
  name?: string;
  type?: string; // TODO: change the type to appropriate string literal
  class?: string;
  required?: boolean;
  requiredSign?: string; // e.g. *
  errorMessage?: string;
  isSecret?: boolean;
  inputClass?: string;
};

const props = defineProps<InputProps>();

const fieldId = useId();
const { t, te } = useI18n();

const { errorMessage: _errorMessage, value } = useField(
  () => props.id ?? props.name ?? fieldId!,
  undefined,
  {
    syncVModel: true,
  }
);

const isSecretVisible = ref(false);

const errorMessage = computed<string | undefined>(() => {
  const message = _errorMessage.value || props.errorMessage;
  if (!message) return;

  if (te(message)) {
    return t(message);
  }

  return message;
});
</script>
